import { createTheme } from '@mui/material';
import { defaultTheme } from './default-theme';

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: defaultTheme.color.primary,
    },
    clientPrimary: {
      main: defaultTheme.color.primary,
    },
    secondary: {
      main: defaultTheme.color.secondary,
    },
    text: {
      secondary: defaultTheme.color.light,
    },
  },
  typography: {
    // useNextVariants: true,
    fontFamily: defaultTheme.font.normal,
    // title: { fontWeight: 'bold', fontSize: '2px' },
    // caption: { fontSize: '0.6rem' },
    // subtitle1: { fontSize: '0.70rem' }, // I don't know how to add new fonts so just changing caption for now
    body1: { fontSize: '0.875rem' },
    overline: { fontSize: '0.5rem', lineHeight: 1.6 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '16px',
          padding: '4px 8px',
        },
      },
    },
  },
});

export const lightInputTheme = createTheme({
  ...muiTheme,
  palette: {
    clientPrimary: {
      main: defaultTheme.color.light,
      light: defaultTheme.color.light,
      dark: defaultTheme.color.light,
      contrastText: defaultTheme.color.primary,
    },
    text: {
      primary: defaultTheme.color.light,
      secondary: defaultTheme.color.light,
    },
  },
});
